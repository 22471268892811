import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './random-tracks.module.scss';
import Panel from '../panel/Panel.jsx';
import RefreshButton from '../buttons/RefreshButton.jsx';
import axiosClient from '../../actions/axiosClient';
import RandomTrack from './RandomTrack.jsx';

export default class RandomTracks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tracks: this.props.tracks,
      loading: false,
    };
    this.handleRefreshRandomClick = this.handleRefreshRandomClick.bind(this);
  }

  handleRefreshRandomClick() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axiosClient
          .get('/v1/random_tracks?count=6')
          .then((response) => {
            this.setState({
              tracks: response.data.tracks,
              loading: false,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  }

  render() {
    return (
      <Panel
        customComponent={
          <RefreshButton
            isLoading={this.state.loading}
            onRefreshRandomClick={this.handleRefreshRandomClick}
            title="Albums"
          />
        }
        subtitle="Tracks"
        title="Listen"
      >
        <div className={styles.container}>
          {this.state.tracks.map((track) => (
            <RandomTrack
              key={track.id}
              currentTrackId={this.props.currentTrackId}
              isPlaying={this.props.currentTrackId === parseInt(track.id, 10)}
              onAddClick={this.props.onAddClick}
              onPauseTrack={this.props.onPauseTrack}
              onPlayTrack={this.props.onPlayTrack}
              track={track}
            />
          ))}
        </div>
      </Panel>
    );
  }
}
RandomTracks.propTypes = {
  currentTrackId: PropTypes.number,
  onAddClick: PropTypes.func,
  onPauseTrack: PropTypes.func.isRequired,
  onPlayTrack: PropTypes.func.isRequired,
  tracks: PropTypes.array.isRequired,
};
