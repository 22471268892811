export const linkSites = {
  iTunes: 'iTunes',
  spotify: 'Spotify',
  home: 'Home',
  wikipedia: 'Wikipedia',
  twitter: 'Twitter',
  email: 'Email',
  facebook: 'Facebook',
};

export const featureCards = {
  composer: 'featured-composer',
  album: 'featured-album',
};
