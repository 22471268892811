import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
import CaretDown from '../icons/CaretDown.jsx';
import CaretUp from '../icons/CaretUp.jsx';
import TracksList from '../tracks/TracksList.jsx';
import styles from './albums-list-item.module.scss';

const AlbumsListItem = (props) => {
  const trackListHeight =
    props.activeAlbumId === parseInt(props.album.id, 10) ? 'auto' : 0;
  return (
    <div className={styles.container}>
      <div className={styles.albumWrapper}>
        <div className={styles.image}>
          <a href={`/albums/${props.album.slug}`}>
            <img src={props.album.cover_image} alt={props.album.title} />
          </a>
        </div>
        <div className={styles.content}>
          <div className={styles.heading}>
            <a href={`/albums/${props.album.slug}`}>
              <h4>{props.album.title}</h4>
            </a>
          </div>
          <div className={styles.info}>
            <span className={styles.releaseDate}>
              Released on{' '}
              {moment(props.album.release_date).format('MMMM D, YYYY')}
            </span>
          </div>
          <div className={styles.tracksList}>
            <button
              className={styles.tracksListToggle}
              onClick={() =>
                props.onToggleAlbum(props.album.id, props.album.title)
              }
            >
              {props.activeAlbumId === parseInt(props.album.id, 10) ? (
                <span>
                  Hide Tracks <CaretUp />
                </span>
              ) : (
                <span>
                  Show Tracks <CaretDown />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      <AnimateHeight duration={500} height={trackListHeight}>
        <TracksList {...props} tracks={props.album.tracks} />
      </AnimateHeight>
    </div>
  );
};
AlbumsListItem.propTypes = {
  album: PropTypes.object.isRequired,
  activeAlbumId: PropTypes.number.isRequired,
  onToggleAlbum: PropTypes.func.isRequired,
};

export default AlbumsListItem;
