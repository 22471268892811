import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Howl } from 'howler';
import noop from 'lodash/noop';

import { easeOutCubic } from '../../helpers/easing';
import {
  getStopIconPoints,
  getPlayIconPoints,
} from '../../helpers/icon-points';
import idGenerator from '../../helpers/id-generator';

class PlayButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      loading: true,
      iconPoints: getPlayIconPoints({
        size: props.size,
        progressCircleWidth: 5,
      }),
    };

    this.updateProgress = this.updateProgress.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  componentDidMount() {
    this.setupHowler();
  }

  componentWillUnmount() {
    this.howler.unload();
  }

  componentDidUpdate(prevProps) {
    // Figure out what needs to happen with these new props.
    const justStartedPlaying = !prevProps.active && this.props.active;
    const justStoppedPlaying = prevProps.active && !this.props.active;
    const newAudioClip = prevProps.url !== this.props.url;

    if (justStartedPlaying) {
      this.triggerPlayAudio();
    } else if (justStoppedPlaying) {
      this.triggerStopAudio();
    }

    if (newAudioClip) {
      this.setupHowler();
    }
  }

  triggerPlayAudio() {
    // Tell howler to drop the beat.
    this.howler.play();
    this.howler.fade(0, 1, 250);

    // Morph our icon into a stop button
    this.animateIcon('stop');

    // Reset the progress bar, and start animating it.
    this.setState({ progress: 0 }, () => this.updateProgress());
  }

  triggerStopAudio() {
    this.howler.fade(1, 0, 250);
    setTimeout(() => this.howler.stop(), 250);

    this.animateIcon('play');

    this.setState({ progress: 0 });
  }

  setupHowler() {
    // If we have a currently-loaded howler, unload it so we can load our new sound.
    if (this.howler && this.howler.unload) {
      this.howler.unload();
    }

    this.howler = new Howl({
      src: [this.props.url],
      format: this.props.audioFormat,
      html5: true,
      onend: () => {
        if (this.props.canAddClicks) {
          this.props.onAddClick(this.props.trackId, this.props.albumSlug);
        }
        this.props.stop();
      },
      onload: () => {
        this.setState({
          loading: false,
          duration: this.howler.duration(this.props.audioId) * 1000,
        });
      },
      preload: false,
    });
  }

  clickHandler() {
    this.props.active ? this.props.stop() : this.props.play(this.props.audioId);
  }

  updateProgress() {
    requestAnimationFrame(() => {
      if (!this.props.active) {
        return;
      }

      this.setState({
        progress: (this.howler.seek() * 1000) / this.state.duration,
      });

      this.updateProgress();
    });
  }

  animateIcon(shape) {
    const easingFunction = easeOutCubic;
    const startTime = new Date().getTime();
    const duration = this.props.iconAnimationLength;
    const initialPoints = this.state.iconPoints;
    const finalPoints =
      shape === 'stop'
        ? getStopIconPoints({ size: this.props.size, progressCircleWidth: 5 })
        : getPlayIconPoints({ size: this.props.size, progressCircleWidth: 5 });

    const updatePosition = () => {
      requestAnimationFrame(() => {
        const time = new Date().getTime() - startTime;

        // Our end condition. The time has elapsed, the animation is completed.
        if (time > duration) {
          return;
        }

        // Let's figure out where the new points should be.
        // There are a total of 8 numbers to work out (4 points, each with x/y).
        // easiest way is probably just to map through them.
        const newPoints = initialPoints.map((initialPoint, index) => {
          const [initialX, initialY] = initialPoint;
          const [finalX, finalY] = finalPoints[index];

          return [
            easingFunction(time, initialX, finalX - initialX, duration),
            easingFunction(time, initialY, finalY - initialY, duration),
          ];
        });

        this.setState(
          {
            iconPoints: newPoints,
          },
          updatePosition
        );
      });
    };

    updatePosition();
  }

  renderIcon() {
    const { active } = this.props;
    const points = this.state.iconPoints.map((p) => p.join(',')).join(' ');

    return (
      <polygon
        points={points}
        style={{ cursor: 'pointer' }}
        fill={active ? '#d3d3d3' : '#d3d3d3'}
      />
    );
  }

  renderMainCircle() {
    const radius = this.props.size / 2;
    return (
      <circle
        cx={radius}
        cy={radius}
        r={radius}
        style={{ cursor: 'pointer' }}
        fill={this.props.active ? '#4c7e64' : '#457e9a'}
      />
    );
  }

  renderProgressBar() {
    const { size } = this.props;

    const center = size / 2;
    const diameter = size - 5;
    const radius = diameter / 2;
    const circumference = diameter * Math.PI;
    const progressWidth = Math.floor(
      1 - (1 - this.state.progress) * circumference
    );

    const circlePath = `
      M ${center}, ${center}
      m 0, -${radius}
      a ${radius},${radius} 0 1,0 0,${diameter}
      a ${radius},${radius} 0 1,0 0,-${diameter}
    `;

    return (
      <path
        d={circlePath}
        stroke="#78A931"
        strokeWidth={5}
        strokeDasharray={circumference}
        style={{
          cursor: 'pointer',
          strokeDashoffset: isNaN(progressWidth) ? 0 : progressWidth,
        }}
        fill="transparent"
      />
    );
  }

  render() {
    const { size, active } = this.props;

    return (
      <svg
        width={size}
        height={size}
        onClick={this.clickHandler}
        viewBox="-2 -2 54 54"
      >
        {this.renderMainCircle()}
        {active ? this.renderProgressBar() : null}
        {this.renderIcon()}
      </svg>
    );
  }
}

PlayButton.propTypes = {
  active: PropTypes.bool,
  onAddClick: PropTypes.func,
  albumSlug: PropTypes.string,
  audioFormat: PropTypes.string,
  audioId: PropTypes.string,
  canAddClicks: PropTypes.bool,
  iconAnimationLength: PropTypes.number,
  play: PropTypes.func,
  size: PropTypes.number,
  stop: PropTypes.func,
  trackId: PropTypes.number,
  trackTitle: PropTypes.string,
  url: PropTypes.string.isRequired,
};

PlayButton.defaultProps = {
  play: noop,
  stop: noop,
  audioId: idGenerator(),
  audioFormat: 'mp3',
  size: 45,
  iconAnimationLength: 450,
};

export default PlayButton;
