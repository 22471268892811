import React from "react";
import PropTypes from "prop-types";
import styles from "./random-track.module.scss";
import AudioPlayerFancy from "../audioplayer/AudioPlayerFancy.jsx";

const RandomTrack = ({
  currentTrackId,
  isPlaying,
  onAddClick,
  onPauseTrack,
  onPlayTrack,
  track,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.topRow}>
        <a href={`/albums/${track.album.slug}`}>
          <h5>
            {track.title.length < 40
              ? track.title
              : `${track.title.substring(0, 36)}...`}
          </h5>
        </a>
      </div>
      <div
        className={styles.middleRow}
        style={{
          backgroundImage: `url('${track.image}'), url('/images/AlbumIcon.svg')`,
        }}
      >
        {track.preview_url ? (
          <AudioPlayerFancy
            onAddClick={onAddClick}
            canAddClicks={true}
            currentTrackNumber={track.track_number}
            currentTrackId={currentTrackId}
            isPlaying={isPlaying}
            onPauseTrack={onPauseTrack}
            onPlayTrack={onPlayTrack}
            size={50}
            track={track}
            trackUrl={track.preview_url}
          />
        ) : null}
      </div>
      <a href={`/composers/${track.composer.slug}`}>
        <div className={styles.bottomRow}>
          <h4>{track.composer.last_name}</h4>
        </div>
      </a>
    </div>
  </div>
);
RandomTrack.propTypes = {
  currentTrackId: PropTypes.number,
  isPlaying: PropTypes.bool,
  onAddClick: PropTypes.func,
  onPauseTrack: PropTypes.func.isRequired,
  onPlayTrack: PropTypes.func.isRequired,
  track: PropTypes.object.isRequired,
};

export default RandomTrack;
