/**
 * Created by jesshendricks on 5/27/16.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlayButton from './PlayButton.jsx';

export default class AudioPlayerFancy extends Component {
  constructor(props) {
    super(props);
    this.togglePlaying = this.togglePlaying.bind(this);
  }
  componentWillUnmount() {
    if (this.props.trackUrl === this.props.currentTrackUrl) {
      this.props.onPauseTrack(this.props.audio);
    }
  }

  togglePlaying() {
    if (this.props.track.id !== this.props.currentTrackId) {
      this.props.onPlayTrack(this.props.track.id, true);
    } else {
      this.props.onPauseTrack();
    }
  }

  render() {
    return (
      <PlayButton
        active={
          this.props.track.id === this.props.currentTrackId &&
          this.props.isPlaying
        }
        albumSlug={this.props.track.album.slug}
        trackId={this.props.track.id}
        trackTitle={this.props.track.title}
        audioId={this.props.track.spotify_id}
        url={this.props.track.preview_url}
        size={this.props.size}
        play={this.togglePlaying}
        stop={this.togglePlaying}
        onAddClick={this.props.onAddClick}
        canAddClicks={this.props.canAddClicks}
      />
    );
  }
}

AudioPlayerFancy.propTypes = {
  audio: PropTypes.object,
  canAddClicks: PropTypes.bool,
  currentTrackId: PropTypes.number,
  currentTrackUrl: PropTypes.string,
  isPlaying: PropTypes.bool,
  onAddClick: PropTypes.func,
  onPauseTrack: PropTypes.func.isRequired,
  onPlayTrack: PropTypes.func.isRequired,
  size: PropTypes.number,
  track: PropTypes.object.isRequired,
  trackUrl: PropTypes.string,
};
