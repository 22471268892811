import PropTypes from 'prop-types';
import React, { Component } from 'react';

import axiosClient from '../actions/axiosClient';

// Components
import FeaturedCard from '../components/features/FeaturedCard.jsx';
import LatestReleases from '../components/latestreleases/LatestReleases.jsx';
import RandomComposers from '../components/randomcomposers/RandomComposers.jsx';
import RandomTracks from '../components/randomtracks/RandomTracks.jsx';
import TagCloudComponent from '../components/tagcloud/TagCloudComponent.jsx';

// Container
import PageContainer from '../containers/PageContainer.jsx';

import styles from './landing-page.module.scss';
import { featureCards } from '../helpers/enums';

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrackId: -1,
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handlePauseTrack = this.handlePauseTrack.bind(this);
    this.handlePlayTrack = this.handlePlayTrack.bind(this);
  }

  handleAddClick(trackId, albumSlug) {
    axiosClient.patch(`/v1/albums/${albumSlug}/add_album_click`);
  }

  handlePauseTrack() {
    this.setState({
      currentTrackId: -1,
    });
  }

  handlePlayTrack(trackId) {
    this.setState({
      currentTrackId: parseInt(trackId, 10),
    });
  }

  get colorOptions() {
    return {
      luminosity: 'light',
      hue: 'blue',
    };
  }

  customRenderer(tag, size, color) {
    return (
      <a
        href={`/composers?tags=${tag.name}`}
        key={tag.id}
        className={styles.tag}
      >
        <span style={{ color, fontSize: size }}>{tag.name}</span>
      </a>
    );
  }

  render() {
    return (
      <PageContainer
        settings={this.props.settings}
        user={this.props.user}
        url="/"
      >
        <div className={styles.container}>
          <div className={styles.tagCloud}>
            <TagCloudComponent tags={this.props.tags} />
          </div>
          <div className={styles.featuredComposer}>
            <FeaturedCard
              heading="Featured Composer"
              image={this.props.settings.featuredComposer.image}
              itunesUrl={this.props.settings.featuredComposer.itunes_url}
              spotifyUrl={this.props.settings.featuredComposer.spotify_url}
              title={this.props.settings.featuredComposer.name}
              cardType={featureCards.composer}
              url={`/composers/${this.props.settings.featuredComposer.slug}`}
            />
          </div>
          <div className={styles.randomComposers}>
            <RandomComposers composers={this.props.randomComposers} />
          </div>
          <div className={styles.bannerAd1}>
            <a
              href="https://existentialmusic.com?utm_source=living_music_database&utm_medium=banner&utm_id=101"
              target="_blank"
              rel="noreferrer"
              id="existentialLink"
            >
              <img
                src="/images/Banner1.jpg"
                alt="Listen to Epic Orchestral Music, Click here"
              />
            </a>
          </div>
          <div className={styles.randomTracks}>
            <RandomTracks
              currentTrackId={this.state.currentTrackId}
              currentTrackUrl={this.state.currentTrackUrl}
              onAddClick={this.handleAddClick}
              onPauseTrack={this.handlePauseTrack}
              onPlayTrack={this.handlePlayTrack}
              tracks={this.props.randomTracks}
            />
          </div>
          <div className={styles.featuredAlbum}>
            <FeaturedCard
              heading="Featured Album"
              image={this.props.settings.featuredAlbum.image}
              itunesUrl={this.props.settings.featuredAlbum.itunes_url}
              spotifyUrl={this.props.settings.featuredAlbum.spotify_url}
              title={this.props.settings.featuredAlbum.title}
              cardType={featureCards.album}
              url={`/albums/${this.props.settings.featuredAlbum.slug}`}
            />
          </div>
          <div className={styles.bannerAd2}>
            <a
              href="https://jesshendricks.bandcamp.com?utm_source=living_music_database&utm_medium=banner&utm_id=102"
              target="_blank"
              rel="noreferrer"
              id="bandcampLink"
            >
              <img
                src="/images/Banner2.jpg"
                alt="Check out music on Bandcamp, Click here"
              />
            </a>
          </div>
          <div className={styles.newReleases}>
            <LatestReleases
              albums={this.props.latestReleases}
              currentTrackId={this.state.currentTrackId}
              onAddClick={this.handleAddClick}
              onPauseTrack={this.handlePauseTrack}
              onPlayTrack={this.handlePlayTrack}
            />
          </div>
        </div>
      </PageContainer>
    );
  }
}
LandingPage.propTypes = {
  latestReleases: PropTypes.array.isRequired,
  randomComposers: PropTypes.array.isRequired,
  randomTracks: PropTypes.array.isRequired,
  settings: PropTypes.shape({
    bannerBackgroundColor: PropTypes.string.isRequired,
    calltoAction: PropTypes.string.isRequired,
    cTABackgroundColor: PropTypes.string.isRequired,
    featuredAlbum: PropTypes.object.isRequired,
    featuredComposer: PropTypes.object.isRequired,
    siteDescription: PropTypes.string.isRequired,
    siteSubtitle: PropTypes.string.isRequired,
    siteTitle: PropTypes.string.isRequired,
  }),
  tags: PropTypes.array.isRequired,
  user: PropTypes.object,
};
