import ReactOnRails from 'react-on-rails';

import AlbumPage from '../bundles/LivingMusicDatabase/pages/AlbumPage.jsx';
import AlbumsPage from '../bundles/LivingMusicDatabase/pages/AlbumsPage.jsx';
import ComposerPage from '../bundles/LivingMusicDatabase/pages/ComposerPage.jsx';
import ComposersPage from '../bundles/LivingMusicDatabase/pages/ComposersPage.jsx';
import LandingPage from '../bundles/LivingMusicDatabase/pages/LandingPage.jsx';
import PrivacyPolicy from '../bundles/LivingMusicDatabase/pages/PrivacyPolicy.jsx';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  AlbumPage,
  AlbumsPage,
  ComposerPage,
  ComposersPage,
  LandingPage,
  PrivacyPolicy,
});
