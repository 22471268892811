import React from 'react';
import Loading from '../loadingoverlay/Loading.jsx';
import styles from './albums-list-item.module.scss';

const AlbumsLoadingListItem = () => (
  <div className={styles.container}>
    <div className={styles.albumWrapper}>
      <Loading />
    </div>
  </div>
);

export default AlbumsLoadingListItem;
