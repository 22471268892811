import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";

import axiosClient from "../actions/axiosClient";

// Components
import AlbumComposers from "../components/albums/AlbumComposers.jsx";
import Panel from "../components/panel/Panel.jsx";
import ServiceButton from "../components/buttons/ServiceButton.jsx";
import TracksList from "../components/tracks/TracksList.jsx";

// Container
import PageContainer from "../containers/PageContainer.jsx";

import styles from "./album-page.module.scss";

export default class AlbumPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrackId: -1,
      loading: false,
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handlePauseTrack = this.handlePauseTrack.bind(this);
    this.handlePlayTrack = this.handlePlayTrack.bind(this);
  }

  handleAddClick(trackId, albumSlug) {
    axiosClient.patch(`/v1/albums/${albumSlug}/add_album_click`);
  }

  handlePauseTrack() {
    this.setState({
      currentTrackId: -1,
    });
  }

  handlePlayTrack(trackId) {
    this.setState({
      currentTrackId: parseInt(trackId, 10),
    });
  }

  render() {
    const album = this.props.album;
    const composers = album.composers;
    const itunesUrl = this.props.album.itunes_url;
    const spotifyUrl = this.props.album.spotify_uri;
    const tracks = this.props.album.tracks;
    return (
      <PageContainer
        settings={this.props.settings}
        shareText={`${album.title}, released on ${moment(
          album.release_date
        ).format("MMMM D, YYYY")}`}
        shareTitle={album.title}
        user={this.props.user}
        url={`/albums/${album.slug}`}
      >
        <div className={styles.container}>
          <Panel
            subtitle={`released on ${moment(album.release_date).format(
              "MMMM D, YYYY"
            )}`}
            title={album.title}
          >
            <div className={styles.album}>
              <div className={styles.image}>
                <img src={album.cover_image} alt={album.title} />
              </div>
              <div className={styles.services}>
                {spotifyUrl ? (
                  <ServiceButton
                    itemName={album.title}
                    serviceUrl={spotifyUrl}
                    serviceName="Spotify"
                  />
                ) : null}
                {itunesUrl ? (
                  <ServiceButton
                    itemName={album.title}
                    serviceUrl={itunesUrl}
                    serviceName="iTunes"
                  />
                ) : null}
              </div>
              <div className={styles.composers}>
                <h4>Featured Composers</h4>
                <AlbumComposers composers={composers} />
              </div>
            </div>
            <TracksList
              currentTrackId={this.state.currentTrackId}
              onAddClick={this.handleAddClick}
              onPauseTrack={this.handlePauseTrack}
              onPlayTrack={this.handlePlayTrack}
              tracks={tracks}
            />
          </Panel>
        </div>
      </PageContainer>
    );
  }
}
AlbumPage.propTypes = {
  album: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  user: PropTypes.object,
};
