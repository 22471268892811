import React from 'react';
import PropTypes from 'prop-types';
import ServiceButton from '../buttons/ServiceButton.jsx';
import ShareButton from '../buttons/ShareButton.jsx';
import styles from './featured-card.module.scss';
import { linkSites } from '../../helpers/enums';

const FeaturedCard = ({
  heading,
  image,
  itunesUrl,
  spotifyUrl,
  title,
  cardType,
  url,
}) => (
  <div
    className={styles.featuredCard}
    style={{
      backgroundImage: `url('${image}')`,
    }}
  >
    <a href={url} id={`${cardType}Link`}>
      <div className={`home-${cardType}-link ${styles.featuredTopBar}`}>
        <h5 className={`home-${cardType}-link ${styles.featuredHeading}`}>
          {heading}
        </h5>
        <h2 className={`home-${cardType}-link ${styles.featuredName}`}>
          {title}
        </h2>
      </div>
    </a>
    <div className={styles.services}>
      {spotifyUrl ? (
        <ServiceButton
          itemName={title}
          serviceUrl={spotifyUrl}
          serviceName={linkSites.spotify}
        />
      ) : null}
      {itunesUrl ? (
        <ServiceButton
          itemName={title}
          serviceUrl={itunesUrl}
          serviceName={linkSites.iTunes}
        />
      ) : null}
    </div>
    <div className={styles.sharing}>
      <ShareButton
        socialNetwork={linkSites.facebook}
        url={`https://www.livingmusicdatabase.com${url}`}
      />
      <ShareButton
        socialNetwork={linkSites.twitter}
        text={escape(`Check out ${title} on LivingMusicDatabase.com`)}
        url={`https://www.livingmusicdatabase.com${url}`}
      />
      <ShareButton
        socialNetwork={linkSites.email}
        subject={escape(`Check out ${title} on LivingMusicDatabase.com`)}
        text={escape(`${title}`)}
        url={`https://www.livingmusicdatabase.com${url}`}
      />
    </div>
  </div>
);
FeaturedCard.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  itunesUrl: PropTypes.string,
  spotifyUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default FeaturedCard;
