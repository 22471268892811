import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

import styles from "./pagination-style.module.scss";

const Pagination = (props) =>
  props.totalPages > 1 ? (
    <ReactPaginate
      previousLabel={"prev"}
      nextLabel={"next"}
      breakLabel=". . ."
      breakClassName={styles.break}
      pageCount={props.totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      onPageChange={props.onPageClick}
      containerClassName={styles.pagination}
      pageClassName={styles.page}
      pageLinkClassName={styles.pageLink}
      nextClassName={styles.next}
      nextLinkClassName={styles.nextLink}
      previousClassName={styles.previous}
      previousLinkClassName={styles.previousLink}
      disabledClassName={styles.disabled}
      activeClassName={styles.active}
    />
  ) : (
    <div></div>
  );

Pagination.propTypes = {
  onPageClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default Pagination;
