import React from 'react';
import PropTypes from 'prop-types';
import styles from './refresh-button.module.scss';

const RefreshButton = ({ isLoading, onRefreshRandomClick, title }) => {
  const buttonClasses = [styles.refreshButton];
  if (isLoading) {
    buttonClasses.push(styles.loadingRefreshButton);
  }

  return (
    <button
      className={buttonClasses.join(' ')}
      disabled={isLoading}
      onClick={onRefreshRandomClick}
      aria-label={`Refresh Random ${title}`}
      id={`refreshRandom${title}`}
    >
      <svg>
        <circle cx="1rem" cy="1rem" r="1rem" />
        <g transform="matrix(0.857143,0,0,0.875,4,2)">
          <path d="M23.883,6.086L21.063,8.918C22.953,10.809 24,13.324 24,16C24,21.516 19.516,26 14,26L14,24L10,28L14,32L14,30C21.719,30 28,23.719 28,16C28,12.254 26.539,8.734 23.883,6.086ZM18,4L14,0L14,2C6.281,2 0,8.281 0,16C0,19.746 1.461,23.266 4.117,25.914L6.937,23.082C5.047,21.191 4,18.676 4,16C4,10.484 8.484,6 14,6L14,8L18,4Z" />
        </g>
      </svg>
    </button>
  );
};
RefreshButton.propTypes = {
  isLoading: PropTypes.bool,
  onRefreshRandomClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default RefreshButton;
