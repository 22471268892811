import PropTypes from "prop-types";
import React from "react";

import styles from "./tag-style.module.scss";

const Tag = ({ tag, size, color }) => (
  <span className={styles.tag}>
    <a href={`/composers?tags=${tag.value}`} style={{ color, fontSize: size }}>
      {tag.value}
    </a>{" "}
  </span>
);

Tag.propTypes = {
  tag: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Tag;
