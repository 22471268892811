import React from "react";
import PropTypes from "prop-types";
import Gravatar from "react-gravatar";
import styles from "./nav-item.module.scss";

const NavItem = ({ icon, link, title, user }) =>
  icon ? (
    <li className={styles.navItem}>
      <a href={link}>
        <img src={icon} alt={title} />
        <span className={styles.navText}>{title}</span>
      </a>
    </li>
  ) : (
    <li className={styles.navItem}>
      <a href={link}>
        <Gravatar email={user.email} rating="pg" />
        <span className={styles.navText}>{title}</span>
      </a>
    </li>
  );
NavItem.propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default NavItem;
