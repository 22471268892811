import React from 'react';
import PropTypes from 'prop-types';
import styles from './service-button.module.scss';

const ServiceButton = ({ serviceUrl, serviceName }) => (
  <div className={styles.serviceButton}>
    <a
      href={serviceUrl}
      id={`${serviceName.toLowerCase()}Link`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`View on ${serviceName}`}
    >
      <img
        src={
          serviceName === 'iTunes'
            ? '/images/Get_it_on_iTunes_Badge_US_1114.svg'
            : '/images/Spotify_Logo_RGB_White.png'
        }
        className={
          serviceName === 'iTunes' ? styles.iTunesImage : styles.spotifyImage
        }
        alt={`${serviceName} Button`}
      />
    </a>
  </div>
);

ServiceButton.propTypes = {
  serviceUrl: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
};

export default ServiceButton;
