import React from "react";
import PropTypes from "prop-types";
import TracksListItem from "./TracksListItem.jsx";
import styles from "./tracks-list.module.scss";

const TracksList = (props) => (
  <div className={props.tracks.length > 0 ? styles.container : ""}>
    {props.tracks.map((track) => (
      <TracksListItem
        key={track.id}
        currentTrackId={props.currentTrackId}
        isPlaying={props.currentTrackId === parseInt(track.id, 10)}
        onAddClick={props.onAddClick}
        onPauseTrack={props.onPauseTrack}
        onPlayTrack={props.onPlayTrack}
        track={track}
      />
    ))}
  </div>
);
TracksList.propTypes = {
  currentTrackId: PropTypes.number,
  onAddClick: PropTypes.func,
  onPauseTrack: PropTypes.func.isRequired,
  onPlayTrack: PropTypes.func.isRequired,
  tracks: PropTypes.array.isRequired,
};

export default TracksList;
