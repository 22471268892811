import React from 'react';
import PropTypes from 'prop-types';

const CaretUp = () => (
  <svg viewBox="0 0 32 16" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px">
    <g transform="matrix(1,0,0,-1,1,31)">
      <path d="M5.5,12L7,10L15,15.5L23,10L24.5,12L15,19L5.5,12Z"/>
    </g>
  </svg>
);
CaretUp.propTypes = {
  fillColor: PropTypes.string,
};
export default CaretUp;
