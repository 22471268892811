import PropTypes from "prop-types";
import React, { Component } from "react";
import { TagCloud } from "react-tagcloud";

import Tag from "./Tag.jsx";

import styles from "./tag-cloud-component.module.scss";

export default class TagCloudComponent extends Component {
  get colorOptions() {
    return {
      luminosity: "light",
      hue: "blue",
    };
  }

  customRenderer(tag, size, color) {
    const textSize = size.isNaN ? 12 : textSize;
    return <Tag key={tag.id} tag={tag} size={size} color={color} />;
  }

  render() {
    return (
      <TagCloud
        className={styles.tagCloud}
        minSize={12}
        maxSize={32}
        colorOptions={this.colorOptions}
        renderer={this.customRenderer}
        shuffle={true}
        tags={this.props.tags}
      />
    );
  }
}

TagCloudComponent.propTypes = {
  tags: PropTypes.array.isRequired,
};
