import React from "react";
import PropTypes from "prop-types";
import styles from "./panel-style.module.scss";

const Panel = ({ children, customComponent, subtitle, title }) => (
  <div className={styles.container}>
    <div className={styles.heading}>
      <h1>
        {title} <small>{subtitle}</small>
      </h1>
      {customComponent ? customComponent : null}
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);
Panel.propTypes = {
  children: PropTypes.any,
  customComponent: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default Panel;
