import React from "react";
import PropTypes from "prop-types";
import styles from "./random-composer.module.scss";

const RandomComposer = ({ composer }) => (
  <div
    className={styles.wrapper}
    style={{
      backgroundImage: `url('${composer.image_url}'), url('/images/ComposerIcon.svg')`,
    }}
  >
    <a href={`/composers/${composer.slug}`}>
      <div className={styles.container}>
        <div className={styles.topRow}></div>
        <div className={styles.bottomRow}>
          <h4>
            {composer.first_name} {composer.last_name}
          </h4>
        </div>
      </div>
    </a>
  </div>
);
RandomComposer.propTypes = {
  composer: PropTypes.object.isRequired,
};

export default RandomComposer;
