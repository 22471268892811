import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AlbumsListItem from './AlbumsListItem.jsx';
import AlbumsLoadingListItem from './AlbumsLoadingListItem.jsx';
import styles from './albums-list.module.scss';

export default class AlbumsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAlbumId: -1,
    };
    this.handleToggleAlbum = this.handleToggleAlbum.bind(this);
  }

  handleToggleAlbum(albumId) {
    this.setState({
      activeAlbumId:
        albumId === this.state.activeAlbumId ? -1 : parseInt(albumId, 10),
    });
  }

  render() {
    return this.props.loading ? (
      <div className={styles.container}>
        <AlbumsLoadingListItem />
        <AlbumsLoadingListItem />
        <AlbumsLoadingListItem />
        <AlbumsLoadingListItem />
        <AlbumsLoadingListItem />
        <AlbumsLoadingListItem />
        <AlbumsLoadingListItem />
        <AlbumsLoadingListItem />
      </div>
    ) : (
      <div className={styles.container}>
        {this.props.albums.map((album) => (
          <AlbumsListItem
            {...this.props}
            activeAlbumId={this.state.activeAlbumId}
            album={album}
            key={album.id}
            onToggleAlbum={this.handleToggleAlbum}
          />
        ))}
      </div>
    );
  }
}
AlbumsList.propTypes = {
  albums: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};
