import React from "react";
import PropTypes from "prop-types";
import styles from "./hero-banner.module.scss";

const HeroBanner = ({ backgroundColor, subtitle, title }) => (
  <div className={styles.heroBanner} style={{ backgroundColor }}>
    <h1>
      {title} <small>{subtitle}</small>
    </h1>
  </div>
);
HeroBanner.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default HeroBanner;
