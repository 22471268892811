import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './random-composers.module.scss';
import Panel from '../panel/Panel.jsx';
import RandomComposer from './RandomComposer.jsx';
import RefreshButton from '../buttons/RefreshButton.jsx';
import axiosClient from '../../actions/axiosClient';

export default class RandomComposers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      composers: this.props.composers,
      loading: false,
    };
    this.handleRefreshRandomClick = this.handleRefreshRandomClick.bind(this);
  }

  handleRefreshRandomClick() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axiosClient
          .get('/v1/random_composers?count=6')
          .then((response) => {
            this.setState({
              composers: response.data.composers,
              loading: false,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  }

  render() {
    return (
      <Panel
        customComponent={
          <RefreshButton
            isLoading={this.state.loading}
            onRefreshRandomClick={this.handleRefreshRandomClick}
            title="Composers"
          />
        }
        subtitle="Composers"
        title="Discover"
      >
        <div className={styles.container}>
          {this.state.composers.map((composer) => (
            <RandomComposer composer={composer} key={composer.id} />
          ))}
        </div>
      </Panel>
    );
  }
}
RandomComposers.propTypes = {
  composers: PropTypes.array.isRequired,
};
