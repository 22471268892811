import React from "react";
import PropTypes from "prop-types";
import axiosClient from "../../../actions/axiosClient";
import ShareButton from "../../../components/buttons/ShareButton.jsx";
import styles from "./footer-style.module.scss";

const signOutUser = () => {
  axiosClient.delete("/users/sign_out").then((response) => {
    console.log(response);
    window.location.reload();
  });
};

const Footer = ({
  featuredComposerURL,
  featuredAlbumURL,
  shareTitle = "LivingMusicDatabase",
  shareText = "Discover new music by living composers on LivingMusicDatabase.com",
  siteDescription,
  url,
  user,
}) => (
  <div className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.left}>
          <h3>LivingMusicDatabase</h3>
          <div dangerouslySetInnerHTML={{ __html: siteDescription }} />
          <p>
            iTunes&trade; previews and music courtesy of{" "}
            <a
              href="https://www.apple.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Apple, Inc.
            </a>
          </p>
          <p>
            Spotify&trade; previews and music courtesy of{" "}
            <a
              href="https://www.spotify.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Spotify.
            </a>
          </p>
        </div>
        <div className={styles.right}>
          <h3>Navigation</h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/composers">Composers</a>
            </li>
            <li>
              <a href="/albums">Albums</a>
            </li>
            <li>
              <a href={featuredComposerURL}>Featured Composer</a>
            </li>
            <li>
              <a href={featuredAlbumURL}>Featured Album</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className={styles.bottomRow}>
      <div className={styles.container}>
        <div className={styles.bottomWrapper}>
          <span>
            &copy; {new Date().getFullYear()}
            <a
              href="//www.existentialmusic.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Existential Music
            </a>
          </span>
          <div className={styles.sharing}>
            <ShareButton
              noBackground
              socialNetwork="Facebook"
              url={`https://www.livingmusicdatabase.com${url}`}
            />
            <ShareButton
              noBackground
              socialNetwork="Twitter"
              text={escape(
                `Check out ${shareTitle} on LivingMusicDatabase - ${shareText}`
              )}
              url={`https://www.livingmusicdatabase.com${url}`}
            />
            <ShareButton
              noBackground
              socialNetwork="Email"
              subject={`Check out ${shareTitle} on LivingMusicDatabase`}
              text={`Check out ${shareTitle} on LivingMusicDatabase - ${shareText}`}
              url={`https://www.livingmusicdatabase.com${url}`}
            />
          </div>
          <div>
            <a href="/privacy_policy">Privacy Policy</a>
            {user ? (
              <a className={styles.userButton} onClick={() => signOutUser()}>
                Sign Out
              </a>
            ) : null}
            {!user ? (
              <a className={styles.userButton} href="/users/sign_in">
                Sign In
              </a>
            ) : null}
            {!user ? (
              <a className={styles.userButton} href="/users/sign_up">
                Register
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  featuredComposerURL: PropTypes.string.isRequired,
  featuredAlbumURL: PropTypes.string.isRequired,
  shareTitle: PropTypes.string,
  shareText: PropTypes.string,
  siteDescription: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default Footer;
