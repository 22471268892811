import React from "react";
import PropTypes from "prop-types";

import styles from "./search-form.module.scss";

const SearchForm = ({ searchTerm, onClearSearchForm, onSearchChange }) => (
  <div className={styles.container}>
    <input
      type="text"
      className={styles.searchInput}
      value={searchTerm}
      onChange={onSearchChange}
      placeholder="Search..."
    />
    <button className={styles.searchButton} onClick={onClearSearchForm}>
      <svg height="15" width="15">
        <line x1="0" y1="0" x2="15" y2="15" style={{ strokeWidth: 2 }} />
        <line x1="0" y1="15" x2="15" y2="0" style={{ strokeWidth: 2 }} />
      </svg>
    </button>
  </div>
);

SearchForm.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onClearSearchForm: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};

export default SearchForm;
