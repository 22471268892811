import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";

import axiosClient from "../actions/axiosClient";

// Components
import AlbumsList from "../components/albums/AlbumsList.jsx";
import LinkButton from "../components/buttons/LinkButton.jsx";
import Pagination from "../components/pagination/Pagination.jsx";
import Panel from "../components/panel/Panel.jsx";
import TagCloudComponent from "../components/tagcloud/TagCloudComponent.jsx";

// Container
import PageContainer from "../containers/PageContainer.jsx";

import styles from "./composer-page.module.scss";

export default class ComposerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: [],
      currentTrackId: -1,
      limit: 10,
      loading: true,
      order: "release_date DESC",
      page: 1,
      totalPages: props.composer.albums_count / 10,
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handlePauseTrack = this.handlePauseTrack.bind(this);
    this.handlePlayTrack = this.handlePlayTrack.bind(this);
  }

  componentWillMount() {
    this.getAlbumsFromApi();
  }

  getAlbumsFromApi() {
    const apiParams = {
      composer_id: this.props.composer.id,
      page: this.state.page,
      order: this.state.order,
      per_page: this.state.limit,
    };

    axiosClient
      .get("/v1/albums", { params: apiParams })
      .then((response) => {
        this.setState({
          albums: response.data.albums,
          loading: false,
          totalPages: response.data.meta.total,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleAddClick(trackId, albumSlug) {
    axiosClient.patch(`/v1/albums/${albumSlug}/add_album_click`);
  }

  handlePageClick(pageSelected) {
    const page = pageSelected.selected + 1;
    if (page > 0 && page <= this.props.totalPages) {
      this.setState(
        {
          loading: true,
          page,
        },
        () => {
          this.getAlbumsFromApi();
        }
      );
    }
  }

  handlePauseTrack() {
    this.setState({
      currentTrackId: -1,
    });
  }

  handlePlayTrack(trackId) {
    this.setState({
      currentTrackId: parseInt(trackId, 10),
    });
  }

  get composerImage() {
    if (this.props.composer.image_url && this.props.composer.image_url !== "") {
      return this.props.composer.image_url;
    }
    return "/images/ComposerIcon.svg";
  }

  render() {
    const composerFullName = `${this.props.composer.first_name} ${this.props.composer.last_name}`;
    return (
      <PageContainer
        settings={this.props.settings}
        shareText={this.props.composer.description}
        shareTitle={composerFullName}
        url={`/composers/${this.props.composer.slug}`}
        user={this.props.user}
      >
        <div className={styles.container}>
          <Panel
            subtitle={`Born ${moment(this.props.composer.birthday).format(
              "MMMM D, YYYY"
            )}`}
            title={`${this.props.composer.first_name} ${this.props.composer.last_name}`}
          >
            <div className={styles.composer}>
              <div
                className={styles.image}
                style={{ backgroundImage: `url('${this.composerImage}')` }}
              ></div>
              <div className={styles.description}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.composer.description,
                  }}
                />
              </div>
              {this.props.composer.tags.length > 0 ? (
                <div className={styles.tagCloud}>
                  <TagCloudComponent tags={this.props.composer.tags} />
                </div>
              ) : null}
              <div className={styles.links}>
                {this.props.composer.wiki_url ? (
                  <LinkButton
                    noBackground
                    linkSite="Wikipedia"
                    linkURL={this.props.composer.wiki_url}
                    composerName={composerFullName}
                  />
                ) : null}
                {this.props.composer.home_url ? (
                  <LinkButton
                    noBackground
                    linkSite="Home"
                    linkURL={this.props.composer.home_url}
                    composerName={composerFullName}
                  />
                ) : null}
                {this.props.composer.itunes_url ? (
                  <LinkButton
                    noBackground
                    linkSite="iTunes"
                    linkURL={this.props.composer.itunes_url}
                    composerName={composerFullName}
                  />
                ) : null}
                {this.props.composer.spotify_url ? (
                  <LinkButton
                    noBackground
                    linkSite="Spotify"
                    linkURL={this.props.composer.spotify_url}
                    composerName={composerFullName}
                  />
                ) : null}
              </div>
            </div>
            <Pagination
              onPageClick={this.handlePageClick}
              totalPages={this.props.totalPages}
            />
            <div className={styles.albumWrapper}>
              <AlbumsList
                albums={this.state.albums}
                currentTrackId={this.state.currentTrackId}
                loading={this.state.loading}
                onAddClick={this.handleAddClick}
                onPauseTrack={this.handlePauseTrack}
                onPlayTrack={this.handlePlayTrack}
              />
            </div>
          </Panel>
        </div>
      </PageContainer>
    );
  }
}
ComposerPage.propTypes = {
  composer: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  user: PropTypes.object,
};
