import React from "react";
import PropTypes from "prop-types";
import styles from "./nav-bar.module.scss";
import NavItem from "./NavItem.jsx";

const NavBar = ({ user }) => (
  <nav className={styles.navBar}>
    <div className={styles.navContainer}>
      <a className={styles.navBrand} href="/">
        <img src="/images/LMDLogo4.svg" alt="Logo" />
      </a>
      <ul className={styles.navMenu}>
        <NavItem
          icon="/images/ComposerIcon.svg"
          link="/composers"
          title="Composers"
        />
        <NavItem icon="/images/AlbumIcon.svg" link="/albums" title="Albums" />
        {user && user.admin ? (
          <NavItem
            icon="/images/AdminIcon.svg"
            link="/v1/admin"
            title="Admin"
          />
        ) : null}
        {user && user.moderator ? (
          <NavItem
            icon="/images/AdminIcon.svg"
            link="/v1/admin"
            title="Moderator"
          />
        ) : null}
        {user ? (
          <NavItem link={"/users/edit"} title="Profile" user={user} />
        ) : null}
      </ul>
    </div>
  </nav>
);
NavBar.propTypes = {
  user: PropTypes.object,
};

export default NavBar;
