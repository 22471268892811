import React from 'react';
import PropTypes from 'prop-types';

import styles from './album-composers.module.scss';

const AlbumComposers = ({ composers }) => (
  <div className={styles.composersList}>
    {composers.map((composer) => (
      <div className={styles.albumComposer} key={composer.id}>
        <div className={styles.composerImage}>
          <img
            src={
              composer.image !== ''
                ? composer.image
                : '/images/ComposerIcon.svg'
            }
            alt={composer.name}
          />
        </div>
        <a href={`/composers/${composer.slug}`}>{composer.name}</a>
      </div>
    ))}
  </div>
);

AlbumComposers.propTypes = {
  composers: PropTypes.array.isRequired,
};

export default AlbumComposers;
