import PropTypes from 'prop-types';
import React, { Component } from 'react';

import axiosClient from '../actions/axiosClient';

// Components
import AlbumsList from '../components/albums/AlbumsList.jsx';
import Pagination from '../components/pagination/Pagination.jsx';
import Panel from '../components/panel/Panel.jsx';
import SearchForm from '../components/searchform/SearchForm.jsx';

// Container
import PageContainer from '../containers/PageContainer.jsx';

import styles from './albums-page.module.scss';

export default class AlbumsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: props.albums.albums,
      currentTrackId: -1,
      limit: 10,
      loading: false,
      order: 'release_date DESC',
      page: 1,
      searchTerm: '',
      tags: '',
      totalPages: props.totalPages ? props.totalPages : 0,
      typing: false,
      typingTimeOut: 0,
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleClearSearchForm = this.handleClearSearchForm.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handlePauseTrack = this.handlePauseTrack.bind(this);
    this.handlePlayTrack = this.handlePlayTrack.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  getAlbumsFromApi() {
    const apiParams = {
      page: this.state.page,
      order: this.state.order,
      per_page: this.state.limit,
    };

    if (this.state.searchTerm !== '') {
      apiParams.search = this.state.searchTerm;
    }

    if (this.state.tags !== '') {
      apiParams.tags = this.state.tags;
    }

    axiosClient
      .get('/v1/albums', { params: apiParams })
      .then((response) => {
        this.setState({
          albums: response.data.albums,
          loading: false,
          totalPages: response.data.meta.total,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleAddClick(trackId, albumSlug) {
    axiosClient.patch(`/v1/albums/${albumSlug}/add_album_click`);
  }

  handleClearSearchForm() {
    this.setState(
      {
        order: 'release_date DESC',
        page: 1,
        searchTerm: '',
        typing: false,
        typingTimeOut: 0,
      },
      () => {
        this.getAlbumsFromApi();
      }
    );
  }

  handlePageClick(pageSelected) {
    const page = pageSelected.selected + 1;
    if (page > 0 && page <= this.props.totalPages) {
      this.setState(
        {
          loading: true,
          page,
        },
        () => {
          this.getAlbumsFromApi();
        }
      );
    }
  }

  handlePauseTrack() {
    this.setState({
      currentTrackId: -1,
    });
  }

  handlePlayTrack(trackId) {
    this.setState({
      currentTrackId: parseInt(trackId, 10),
    });
  }

  runSearchTimeout() {
    if (
      this.state.searchTerm.length > 2 ||
      this.state.searchTerm.length === 0
    ) {
      this.setState(
        {
          page: 1,
        },
        () => {
          this.getAlbumsFromApi();
        }
      );
    }
  }

  handleSearchChange(event) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      loading: this.state.searchTerm.length > 2,
      searchTerm: event.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.runSearchTimeout();
      }, 1000),
    });
  }

  render() {
    return (
      <PageContainer
        settings={this.props.settings}
        shareText={'Albums'}
        shareTitle={'a database of albums by living composers'}
        user={this.props.user}
        url="/albums/"
      >
        <div className={styles.container}>
          <Panel subtitle="Browse or search for albums" title="Albums">
            <SearchForm
              searchTerm={this.state.searchTerm}
              onClearSearchForm={this.handleClearSearchForm}
              onSearchChange={this.handleSearchChange}
            />
            <Pagination
              onPageClick={this.handlePageClick}
              totalPages={this.state.totalPages}
            />
            <AlbumsList
              albums={this.state.albums}
              currentTrackId={this.state.currentTrackId}
              loading={this.state.loading}
              onAddClick={this.handleAddClick}
              onPauseTrack={this.handlePauseTrack}
              onPlayTrack={this.handlePlayTrack}
            />
          </Panel>
        </div>
      </PageContainer>
    );
  }
}
AlbumsPage.propTypes = {
  albums: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  user: PropTypes.object,
};
