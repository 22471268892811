/**
 * Created by jesshendricks on 5/14/16.
 */
import React from "react";
import PropTypes from "prop-types";

import styles from "./loading-overlay.module.scss";

const alignmentStyle = (topAlign) => {
  if (topAlign) {
    return {
      alignItems: "flex-start",
      padding: "15% 0 0",
    };
  }

  return {
    alignItems: "center",
    padding: "0",
  };
};

const Loading = ({ topAlign }) => (
  <div className={styles.loadingOverlay} style={alignmentStyle(topAlign)}>
    <div className={styles.loading}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

Loading.propTypes = {
  topAlign: PropTypes.bool,
};

export default Loading;
