import React from 'react';
import PropTypes from 'prop-types';

import ContentWrapper from '../components/layout/ContentWrapper/ContentWrapper.jsx';
import Footer from '../components/layout/Footer/Footer.jsx';
import HeroBanner from '../components/layout/HeroBanner/HeroBanner.jsx';
import NavBar from '../components/layout/NavBar/NavBar.jsx';

import '../stylesheets/_global.scss';

const PageContainer = (props) => (
  <div>
    <NavBar user={props.user} />
    <ContentWrapper>
      <HeroBanner
        backgroundColor={props.settings.bannerBackgroundColor}
        subtitle={props.settings.siteSubtitle}
        title={props.settings.siteTitle}
      />
      {props.children}
    </ContentWrapper>
    <Footer
      user={props.user}
      featuredComposerURL={`/composers/${props.settings.featuredComposer.slug}`}
      featuredAlbumURL={`/albums/${props.settings.featuredAlbum.slug}`}
      shareText={props.shareText}
      shareTitle={props.shareTitle}
      siteDescription={props.settings.siteDescription}
      url={props.url}
    />
  </div>
);

PageContainer.propTypes = {
  children: PropTypes.element.isRequired,
  settings: PropTypes.shape({
    bannerBackgroundColor: PropTypes.string.isRequired,
    calltoAction: PropTypes.string.isRequired,
    cTABackgroundColor: PropTypes.string.isRequired,
    featuredAlbum: PropTypes.object.isRequired,
    featuredComposer: PropTypes.object.isRequired,
    siteDescription: PropTypes.string.isRequired,
    siteSubtitle: PropTypes.string.isRequired,
    siteTitle: PropTypes.string.isRequired,
  }),
  shareTitle: PropTypes.string,
  shareText: PropTypes.string,
  url: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default PageContainer;
