import React from "react";
import AlbumsList from "../albums/AlbumsList.jsx";
import Panel from "../panel/Panel.jsx";
import styles from "./latest-releases.module.scss";

const LatestReleases = (props) => (
  <Panel subtitle="Latest Albums" title="New">
    <div className={styles.container}>
      <AlbumsList {...props} />
    </div>
  </Panel>
);

export default LatestReleases;
