import React from "react";
import PropTypes from "prop-types";
import AudioPlayerFancy from "../audioplayer/AudioPlayerFancy";
import styles from "./tracks-list-item.module.scss";

const TracksListItem = (props) => (
  <div className={styles.container}>
    {props.hideComposer ? null : (
      <h5 className={styles.trackNumber}>{props.track.track_number}</h5>
    )}
    {props.track.preview_url ? (
      <AudioPlayerFancy
        onAddClick={props.onAddClick}
        canAddClicks={true}
        currentTrackNumber={props.track.track_number}
        currentTrackId={props.currentTrackId}
        isPlaying={props.isPlaying}
        onPauseTrack={props.onPauseTrack}
        onPlayTrack={props.onPlayTrack}
        size={40}
        track={props.track}
        trackUrl={props.track.preview_url}
      />
    ) : null}
    <h5 className={styles.title}>{props.track.title}</h5>
    {props.hideComposer ? (
      <h5 className={styles.album}>
        <a href={`/albums/${props.track.album.slug}`}>
          {props.track.album.title}
        </a>
      </h5>
    ) : (
      <h5 className={styles.composer}>
        <a href={`/composers/${props.track.composer.slug}`}>
          {props.track.composer.last_name}
        </a>
      </h5>
    )}
  </div>
);
TracksListItem.propTypes = {
  currentTrackId: PropTypes.number,
  hideComposer: PropTypes.bool,
  isPlaying: PropTypes.bool,
  onAddClick: PropTypes.func,
  onPauseTrack: PropTypes.func.isRequired,
  onPlayTrack: PropTypes.func.isRequired,
  track: PropTypes.object.isRequired,
};

export default TracksListItem;
