import React from 'react';
import PropTypes from 'prop-types';

import TracksListItem from '../tracks/TracksListItem.jsx';

import styles from './composer-style.module.scss';

const Composer = (props) => {
  const composerName = `${props.composer.first_name} ${props.composer.last_name}`;
  const composerImage =
    props.composer.image_url && props.composer.image_url !== ''
      ? props.composer.image_url
      : '/images/ComposerIcon.svg';
  return (
    <div className={styles.composer} key={props.composer.id}>
      <div className={styles.composerInfo}>
        <div className={styles.composerImage}>
          <img src={composerImage} alt={composerName} />
        </div>
        <a href={`/composers/${props.composer.slug}`}>{composerName}</a>
      </div>
      <div className={styles.randomTrackContainer}>
        <TracksListItem
          {...props}
          hideComposer
          isPlaying={
            props.currentTrackId ===
            parseInt(props.composer.random_track.id, 10)
          }
          track={props.composer.random_track}
        />
      </div>
    </div>
  );
};

Composer.propTypes = {
  composer: PropTypes.object.isRequired,
  currentTrackId: PropTypes.number,
};

export default Composer;
